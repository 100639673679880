<template>
	<!--基本布局-->
	<div>
		<!--头部-->
		<layout-header></layout-header>
		<!--搜索-->
		<layout-search></layout-search>
		<!--导航-->
		<layout-nav></layout-nav>
		<!--路由-->
		<div class="me">
			<div class="me-main">
				<div class="breadcrumb">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item><span style="cursor: pointer" @click="$router.push({ path: '/' })">首页</span></el-breadcrumb-item>
						<el-breadcrumb-item>个人中心</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="me-main-center">
					<div class="me-main-left">
						<div v-for="(k, i) in list" :key="i">
							<div class="title">{{ k.title }}</div>
							<div
								:class="[
									'list',
									{
										active: j.path == $route.name || (j.path && $route.meta.contain == j.path)
									}
								]"
								v-for="(j, ji) in k.list"
								:key="ji"
								@click="handleToPath(j)"
							>
								{{ j.title }}
							</div>
						</div>
					</div>
					<div class="me-main-right"><router-view /></div>
				</div>
			</div>
		</div>
		<!--服务-->
		<layout-seivice></layout-seivice>
		<!--底部-->
		<layout-footer></layout-footer>
		<!--固定-->
		<layout-fixed></layout-fixed>
	</div>
</template>

<script>
import LayoutHeader from './components/layout-header';
import LayoutSearch from './components/layout-search';
import LayoutNav from './components/layout-nav';
import LayoutSeivice from './components/layout-seivice';
import LayoutFooter from './components/layout-footer';
import LayoutFixed from './components/layout-fixed';

export default {
	name: 'BasicLayout',
	components: {
		LayoutHeader,
		LayoutSearch,
		LayoutNav,
		LayoutSeivice,
		LayoutFooter,
		LayoutFixed
	},
	data() {
		return {
			list: [
				{
					title: '订单中心',
					list: [
						{
							title: '我的订单',
							active: true,
							path: 'order'
						},
						{
							title: '退款/售后',
							path: 'sales'
						},
						{
							title: '评价晒单',
							path: 'evaluate'
						}
					]
				},
				{
					title: '我的权益',
					list: [
						{
							title: '积分兑换',
							href: 'http://u.hexagonmi.com.cn/mall.aspx'
						},
						{
							title: '我的优惠券',
							path: 'coupon'
						}
					]
				},
				{
					title: '我的账户',
					list: [
						{
							title: 'PLUS会员',
							path: 'PLUS'
						},
						{
							title: '收货地址',
							path: 'addr'
						},
						{
							title: '发票管理',
							path: 'invoice'
						},
						{
							title: '商城积分',
							path: 'point'
						},
						{
							title: '我的足迹',
							path: 'footprint'
						},
						{
							title: '我的收藏',
							path: 'collection'
						}
					]
				},
				{
					title: '服务中心',
					list: [
						{
							title: '消息中心',
							path: 'news'
						}
					]
				}
			]
		};
	},
	mounted() {},
	methods: {
		handleToPath(data) {
			if (data.href) {
				window.open(data.href, '_blank').location;
			} else {
				this.$router.push({
					path: '/me/' + data.path
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.me-main {
	width: 1240px;
	margin: 0 auto;

	.breadcrumb {
		margin: 20px 0;
	}

	.sign {
		color: #0097ba;
	}

	.me-main-center {
		display: flex;
	}

	.me-main-right {
		width: 1070px;
		margin-left: 20px;
		overflow: hidden;
	}

	.me-main-left {
		width: 150px;
		text-align: center;
		background: #f9f9f9;
		border: 1px solid #dcdcdc;
		padding: 20px 10px;
		height: 700px;

		.title {
			font-family: 'ct';
			color: #313131;
			margin: 15px 0;
			text-align: left;
			font-size: 16px;
			padding-left: 8px;
		}

		.list {
			margin: 10px;
			color: #666666;
			cursor: pointer;
			padding: 5px 0;
		}

		.active {
			background: rgba(27, 152, 170, 0.2);
			color: #1b98aa;
			padding: 5px 25px;
		}
	}
}
</style>
